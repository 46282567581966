/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

const React = require("react");
const Helmet = require("react-helmet").default;

// See more at https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapRootElement
exports.wrapRootElement = ({ element }) => {
    const noscript = () => {
        return {
            __html: '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NQG34G8" height="0" width="0" style="display:none; visibility:hidden"/>'
        }
    }

    return (<>
        <Helmet>
            {/* <!-- Google Tag Manager --> */}
            <script src="/gtmInit.js"/>
            {/* <!-- End Google Tag Manager --> */}
        </Helmet>

        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript dangerouslySetInnerHTML={noscript()}/>
        {/* <!-- End Google Tag Manager (noscript) --> */}

        {element}
    </>);
}
