exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-page-js": () => import("./../../../src/pages/blog-page.js" /* webpackChunkName: "component---src-pages-blog-page-js" */),
  "component---src-pages-category-all-js": () => import("./../../../src/pages/category-all.js" /* webpackChunkName: "component---src-pages-category-all-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-homepage-js": () => import("./../../../src/pages/homepage.js" /* webpackChunkName: "component---src-pages-homepage-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-account-chat-js": () => import("./../../../src/pages/my-account/chat.js" /* webpackChunkName: "component---src-pages-my-account-chat-js" */),
  "component---src-pages-my-account-js": () => import("./../../../src/pages/my-account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-my-account-treatments-js": () => import("./../../../src/pages/my-account/treatments.js" /* webpackChunkName: "component---src-pages-my-account-treatments-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-questionnaire-start-page-js": () => import("./../../../src/pages/questionnaire-start-page.js" /* webpackChunkName: "component---src-pages-questionnaire-start-page-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-reset-password-page-js": () => import("./../../../src/pages/reset-password-page.js" /* webpackChunkName: "component---src-pages-reset-password-page-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-faq-category-js": () => import("./../../../src/templates/faq-category.js" /* webpackChunkName: "component---src-templates-faq-category-js" */),
  "component---src-templates-faq-single-page-js": () => import("./../../../src/templates/faq-single-page.js" /* webpackChunkName: "component---src-templates-faq-single-page-js" */),
  "component---src-templates-parent-category-js": () => import("./../../../src/templates/parentCategory.js" /* webpackChunkName: "component---src-templates-parent-category-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-questionnaire-js": () => import("./../../../src/templates/questionnaire.js" /* webpackChunkName: "component---src-templates-questionnaire-js" */)
}

